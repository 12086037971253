<template>
    <div class="content-body">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-8 col-xxl-8" ref="transfer">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Transfer Funds</h4>
                        </div>
                        <div class="card-body">
                            <div class="col-12 mt-3">
                                <label class="col-form-label font-weight-bold">Email</label>
                                <input v-model="transfer.receiver" type="email" class="form-control" placeholder="user@domain.com">
                                <small id="emailHelp" class="form-text text-muted">
                                    The email address must be registered with a valid TrustEarn account
                                </small>
                            </div>
                            <div class="col-12 mt-3">
                                <label class="col-form-label font-weight-bold">Enter an amount</label>
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">$</div>
                                    </div>
                                    <input v-model.number="transfer.amount" type="number" class="form-control" placeholder="amount">
                                </div>
                            </div>
                            <div class="col-12 mt-3 text-center">
                                <button @click.prevent="validateInput" class="btn btn-outline-info">Make Transfer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Transfer from "../../../models/transfer";
import methodsMixin from "../../../utils/methodsMixin";

export default {
    name: "Transfer",
    data(){
        return {
            transfer: new Transfer().data
        }
    },
    mixins: [methodsMixin],
    methods: {
        async addNewTransfer(){
            const loader = this.$loading.show({container: this.$refs.transfer});
            const response = await this.$store.dispatch('transfer/addTransfer', this.transfer);
            if(response.status){
                toastr.success("Transfer successful");
                await this.$router.push({name: 'Transfers'})
            }else{
                toastr.error(response.message);
            }
            loader.hide()
        },
        validateInput(){
            if(typeof this.transfer.amount !== 'number'){
                return toastr.warning("Invalid transfer amount")
            }
            if(this.transfer.amount <= 0){
                return toastr.warning("Invalid transfer amount")
            }
            if(!this.validateEmail(this.transfer.receiver)){
                return toastr.warning("Enter a valid email address")
            }
            return this.addNewTransfer();
        }
    }
}
</script>

<style scoped>

</style>